import React from "react"

import SEO from "../components/SEO/SEO"

const NotFoundPage = () => (
	<>
		<SEO title="404: Not found" />
		<div className="error-page wrapper">
			<h1 className="error-page__heading">404 PAGE NOT FOUND</h1>
			<p className="error-page__sub-heading">Oops! The page that you're looking for has been removed or relocated.</p>
		</div>
	</>
)

export default NotFoundPage
